<template>
  <div>
    <!-- 大屏显示 -->
    <div class="box">
      <!-- <div class="content">
        <div class="content_top">
          <div class="content_top_left">
            <div class="copyright">
              <ul>
                <li @click="checkCzwd">
                  <a target="_back">操作手册</a>
                </li>
                <li class="li">|</li>
                <li @click="checkCjwt">
                  <a target="_back">常见问题</a>
                </li>
                <li class="li">|</li>
                <li>
                  <a href="http://39.107.49.94/consult" target="_back"
                    >留言咨询</a
                  >
                </li>
              </ul>
            </div>
            <div class="content_top_left_bottom">
              <p>联系我们</p>
              <div class="copyright" style="margin-top: 0.0781rem">
                <ul>
                  <li style="margin-right: 30px">
                    <img src="@/assets/image/组88.png" alt="" />
                    <span>010-88110399</span>
                  </li>
                  <li style="margin-right: 30px">
                    <img src="@/assets/image/组89.png" alt="" />
                    <span>service@thunlink.com</span>
                  </li>
                  <li style="margin-right: 30px">
                    <img src="@/assets/image/组90.png" alt="" />
                    <span>contact@thunlink.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="content_top_right">
            <img src="@/assets/image/组91.png" alt="" />
            <p>商驿供应链公众号</p>
          </div>
        </div>
      </div>
      <div class="content_bottom">
        <p>
          版权所有 北京商驿科技有限公司-
          <a
            style="margin-right: 30px"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            >京ICP备2022024109号-2</a
          >
        </p>
      </div> -->
      <div class="leftbox">
        <img src="@/assets/image/email.png" class="icon" />
        <div class="content-box">
          <p>联系邮箱</p>
          <p>商务：contact@thunlink.com</p>
          <p>客服：service@thunlink.com</p>
        </div>
      </div>
      <div class="centerbox">
        <img src="@/assets/image/phone.png" class="icon" />
        <div class="content-box">
          <p>客服热线</p>
          <p style="font-size: 0.0938rem">010-88110399</p>
          <p>公司地址：北京市石景山区泰禾长安中心A座12层</p>
        </div>
      </div>
      <div class="rightbox">
        <div class="righttopbox">
          <div class="lefttop">
            <img src="@/assets/image/qrcode1.jpg" class="qrcode" />
            <p>商驿科技公众号</p>
          </div>
          <div class="righttop">
            <img src="@/assets/image/qrcode2.jpg" class="qrcode" />
            <p>商驿科技小程序</p>
          </div>
        </div>
      </div>
    </div>
    <div class="rightbottombox">
      <p>版权所有 北京商驿科技有限公司 - 京ICP备2022024109号-2</p>
    </div>
    <!-- 小屏显示 -->
    <!-- <div class="moreover">
      <div class="center">
        <div class="moreover_center">
          <ul>
            <li>
              <a href="http://www.thunlink.com" target="_back">操作指引</a>
            </li>
            <li>
              <a href="http://39.107.49.94/inRegardTo" target="_back"
                >常见问题</a
              >
            </li>
            <li>
              <a href="http://39.107.49.94/consult" target="_back">留言咨询</a>
            </li>
          </ul>
          <div class="content_top_right">
            <img src="@/assets/image/组91.png" alt="" />
            <p>商驿供应链公众号</p>
          </div>
          <ul>
            <li style="margin-top: 40px">联系我们</li>
            <li style="font-family: ArialMT; color: #a4a4a4">
              <img src="@/assets/image/组88.png" alt="" />
              010-88110399
            </li>
            <li style="font-family: ArialMT; color: #a4a4a4">
              <img src="@/assets/image/组89.png" alt="" />
              service@thunlink.com
            </li>
            <li style="font-family: ArialMT; color: #a4a4a4">
              <img src="@/assets/image/组90.png" alt="" />
              contact@thunlink.com
            </li>
          </ul>
        </div>
        <div class="content_bottom">
          <p>
            版权所有 北京商驿科技有限公司-
            <a
              style="margin-right: 30px"
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              >京ICP备2022024109号-2</a
            >
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    // 常见问题
    checkCjwt() {
      let url = `http://operate.thunlink.cn/protocol/资料清单,操作指南/3常见问题.pdf`
      var xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'
      xhr.send()
      xhr.onload = function () {
        var fileBlob = xhr.response
        var fileUrl = URL.createObjectURL(fileBlob)
        var addElement = document.createElement('a')
        addElement.href = fileUrl
        addElement.download = '常见问题'
        addElement.click()
        document.body.appendChild(addElement)
      }
    },
    // 操作文档
    checkCzwd() {
      let url = `http://operate.thunlink.cn/protocol/资料清单,操作指南/操作手册.pdf`
      var xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'
      xhr.send()
      xhr.onload = function () {
        var fileBlob = xhr.response
        var fileUrl = URL.createObjectURL(fileBlob)
        var addElement = document.createElement('a')
        addElement.href = fileUrl
        addElement.download = '操作手册'
        addElement.click()
        document.body.appendChild(addElement)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 1.4583rem;
  background-color: #313c61;
  .leftbox {
    display: flex;
    // vertical-align: top;
    .icon {
      margin-top: 0.026rem;
      width: 0.1563rem;
      height: 0.1563rem;
    }
    .content-box {
      margin-left: 0.0521rem;
      line-height: 0.2083rem;

      p {
        font-size: 0.0833rem;
        color: #fff;
      }
    }
  }
  .centerbox {
    display: flex;

    .icon {
      margin-top: 0.026rem;
      width: 0.1563rem;
      height: 0.1563rem;
    }
    .content-box {
      margin-left: 0.0521rem;
      line-height: 0.2083rem;

      p {
        font-size: 0.0833rem;
        color: #fff;
      }
    }
  }

  .rightbox {
    display: flex;
    flex-direction: column;
    line-height: 0.1563rem;
    width: 2.2396rem;
    .righttopbox {
      display: flex;
      justify-content: space-around;
      margin-top: 0.0781rem;
      .lefttop {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          font-size: 0.0833rem;
          color: #fff;
        }
        .qrcode {
          width: 0.625rem;
          height: 0.625rem;
          margin-bottom: 0.026rem;
        }
      }
      .righttop {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          font-size: 0.0833rem;
          color: #fff;
        }
        .qrcode {
          width: 0.625rem;
          height: 0.625rem;
          margin-bottom: 0.026rem;
        }
      }
    }
  }
  .rightbottombox {
    background: #313c61;
    padding-bottom: 0.0781rem;
    text-align: center;
    p {
      box-sizing: border-box;
      padding-top: 15px;
      font-size: 0.0781rem;
      color: #fff;
      border-top: 1px solid rgb(95, 95, 97);
    }
  }

  // .content {
  //   display: flex;
  //   width: 100vw;
  //   height: 1.875rem;

  //   .content_top {
  //     box-sizing: border-box;
  //     padding: 0.3385rem 150px;
  //     display: flex;
  //     width: 100%;
  //     height: 1.875rem;
  //     .content_top_left {
  //       width: 70%;
  //       @media only screen and (min-width: 0px) and (max-width: 1200px) {
  //         width: 100%;
  //       }
  //       height: 100%;
  //       .copyright {
  //         cursor: pointer;
  //         ul {
  //           display: flex;
  //           .li {
  //             color: #fff;
  //             font-size: 0.1042rem;
  //             margin: 0 0.0521rem;
  //           }
  //           li {
  //             a {
  //               font-weight: 500;
  //               color: #ffffff;
  //               font-size: 0.1146rem;
  //               // @media only screen and (min-width: 1100px) and (max-width: 1580px) {
  //               //   font-size: 18px;
  //               // }
  //               // @media only screen and (min-width: 800px) and (max-width: 1199px) {
  //               //   font-size: 16px;
  //               // }
  //             }
  //             span {
  //               font-size: 0.0938rem;
  //               // @media only screen and (min-width: 1100px) and (max-width: 1580px) {
  //               //   font-size: 16px;
  //               // }
  //               // @media only screen and (min-width: 800px) and (max-width: 1199px) {
  //               //   font-size: 14px;
  //               // }
  //               font-family: ArialMT;
  //               color: rgba(255, 255, 255, 0.4);
  //             }
  //           }
  //           h5 {
  //             width: 2px;
  //             height: 26px;
  //             margin: 0.0208rem 0.0417rem;
  //             background-color: #fff;
  //           }
  //         }
  //       }
  //       .content_top_left_bottom {
  //         width: 100%;
  //         margin-top: 0.4167rem;
  //         img {
  //           margin-right: 0.026rem;
  //         }
  //       }
  //       p {
  //         font-size: 0.125rem;
  //         // @media only screen and (min-width: 1200px) and (max-width: 1580px) {
  //         //   font-size: 20px;
  //         // }
  //         // @media only screen and (min-width: 800px) and (max-width: 1199px) {
  //         //   font-size: 18px;
  //         // }
  //         font-weight: 500;
  //         color: #ffffff;
  //       }
  //     }
  //     .content_top_right {
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       align-items: center;
  //       width: 30%;
  //       height: 100%;
  //       img {
  //         width: 150px;
  //         @media only screen and (min-width: 1200px) and (max-width: 1580px) {
  //           width: 100px;
  //         }
  //         @media only screen and (min-width: 800px) and (max-width: 1199px) {
  //           width: 80px;
  //         }
  //       }
  //       p {
  //         margin-top: 15px;
  //         font-size: 0.0938rem;
  //         // @media only screen and (min-width: 1200px) and (max-width: 1580px) {
  //         //   font-size: 15px;
  //         // }
  //         // @media only screen and (min-width: 800px) and (max-width: 1199px) {
  //         //   font-size: 13px;
  //         // }
  //         color: #fff;
  //       }
  //     }
  //   }
  // }
  // .content_bottom {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 100vw;
  //   height: 0.2083rem;
  //   background-color: #313c61;
  //   border-top: 1px solid rgba(255, 255, 255, 0.2);
  //   p {
  //     font-size: 0.0625rem;
  //     font-family: 'Microsoft YaHei';;
  //     font-weight: 400;
  //     color: rgba(255, 255, 255, 0.4);
  //   }
  //   a {
  //     color: rgba(255, 255, 255, 0.4);
  //   }
  // }
}
// 小屏显示
// .moreover {
//   @media only screen and (min-width: 1041px) and (max-width: 2580px) {
//     display: none;
//   }
//   box-sizing: border-box;
//   width: 100vw;
//   background-color: #313c61;

//   .center {
//     // width: 100vw;
//     // background-color: #f00;
//     .top_logo {
//       height: 50px;
//       margin-bottom: 30px;
//       img {
//         width: 115px;
//         height: 34px;
//       }
//     }
//     .moreover_center {
//       width: 10rem;
//       box-sizing: border-box;
//       padding: 15px 20px 3px 15px;

//       // border-bottom: 1px solid #f00;
//       ul {
//         margin-bottom: 40px;

//         li {
//           margin-top: 15px;
//           font-size: 18px;
//           @media only screen and (min-width: 0px) and (max-width: 800px) {
//             font-size: 14px;
//           }
//           // background-color: #f00;
//           color: #fff;
//           margin-bottom: 5px;
//           a {
//             font-size: 18px;
//             @media only screen and (min-width: 0px) and (max-width: 800px) {
//               font-size: 14px;
//             }
//             color: #fff;
//           }
//           img {
//             margin-right: 5px;
//           }
//         }
//       }
//       .content_top_right {
//         img {
//           width: 120px;
//           @media only screen and (min-width: 0px) and (max-width: 800px) {
//             width: 90px;
//           }
//         }
//         p {
//           margin-top: 15px;
//           font-size: 16px;
//           color: #fff;
//         }
//       }
//     }
//   }
// }
</style>
