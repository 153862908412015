import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: Home,
    children: [
      {
        path: '/home',
        name: '首页',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/body.vue')
      },
      {
        path: '/aaa',
        name: '首页1',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/aaa.vue')
      },
      // 商票驿
      {
        path: '/ShangPiao',
        name: '商票驿',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/ShangPiao/index')
      },
      // 票据询价
      {
        path: '/enquiry',
        name: '票据询价',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/ShangPiao/enquiry')
      },
      // 我的票据
      {
        path: '/billAdministration',
        name: '我的票据',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/ShangPiao/billAdministration')
      },
      // 产品申请贴现产品
      {
        path: '/sptxApplication',
        name: 'sptxApplication',
        redirect: '/sptxApplication1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/ShangPiao/ShangPiaoTieXian/sptxApplication.vue'),
        children: [
          {
            path: '/sptxApplication1',
            name: 'sptxApplication1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangPiao/ShangPiaoTieXian/sptxApplication1.vue')
          }
        ]
      },
      // 产品申请质押产品
      {
        path: '/spzyApplication',
        name: 'spzyApplication',
        redirect: '/spzyApplication1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/ShangPiao/ShangPioaZhiYa/spzyApplication.vue'),
        children: [
          {
            path: '/spzyApplication1',
            name: 'spzyApplication1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangPiao/ShangPioaZhiYa/spzyApplication1.vue')
          }
        ]
      },

      // 跨境驿
      {
        path: '/KuaJing',
        name: '跨境驿',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/KuaJing/index')
      },
      // 关税保函产品申请
      // 产品申请
      {
        path: '/gsbhApplocation',
        name: 'gsbhApplocation',
        redirect: '/gsbhApplocation1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/KuaJing/applyFor/gsbh/gsbhApplocation'),
        children: [
          {
            path: '/gsbhApplocation1',
            name: 'gsbhApplocation1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/gsbh/gsbhApplocation1')
          },
          {
            path: '/gsbhzsApplocation',
            name: 'gsbhzsApplocation',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 5,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/gsbh/gsbhzsApplocation')
          }
        ]
      },
      // 税信保
      {
        path: '/sxbApplocation',
        name: 'sxbApplocation',
        redirect: '/sxbApplocation1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/KuaJing/applyFor/sxb/sxbApplocation'),
        children: [
          {
            path: '/sxbApplocation1',
            name: 'sxbApplocation1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/sxb/sxbApplocation1')
          },
          {
            path: '/sxbCreditExtension',
            name: 'sxbCreditExtension',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 5,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/sxb/sxbCreditExtension')
          },
          {
            path: '/sxbCreditExtension2',
            name: 'sxbCreditExtension2',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 5,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/sxb/sxbCreditExtension2')
          }
        ]
      },
      // 关税E保
      {
        path: '/gsebApplocation',
        name: 'gsebApplocation',
        redirect: '/gsebApplocation1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/KuaJing/applyFor/gseb/gsebApplocation'),
        children: [
          {
            path: '/gsebApplocation1',
            name: 'gsebApplocation1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/gseb/gsebApplocation1')
          }
        ]
      },

      // 关税保证保险
      {
        path: '/gsbzbxApplocation',
        name: 'gsbzbxApplocation',
        redirect: '/gsbzbxApplocation1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/KuaJing/applyFor/gsbzbx/gsbzbxApplocation'),
        children: [
          {
            path: '/gsbzbxApplocation1',
            name: 'gsbzbxApplocation1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/gsbzbx/gsbzbxApplocation1')
          }
        ]
      },
      // 关税履约保证保险
      {
        path: '/gslybzbxApplocation',
        name: 'gslybzbxApplocation',
        redirect: '/gslybzbxApplocation1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/KuaJing/applyFor/gslybzbx/gslybzbxApplocation'),
        children: [
          {
            path: '/gslybzbxApplocation1',
            name: 'gslybzbxApplocation1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/KuaJing/applyFor/gslybzbx/gslybzbxApplocation1')
          }
        ]
      },

      // 商链驿
      {
        path: '/ShangLian',
        name: '商链驿',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/ShangLian/index')
      },
      {
        path: '/transitionPage',
        name: 'transitionPage',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/ShangLian/transitionPage')
      },
      // 商链驿 产品申请
      // 保理融资产品申请
      {
        path: '/blrzApplication',
        name: 'blrzApplication',
        redirect: '/blrzApplication1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/ShangLian/baoLiRongZi/blrzApplication'),
        children: [
          {
            path: '/blrzApplication1',
            name: 'blrzApplication1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/baoLiRongZi/blrzApplication1')
          },
          {
            path: '/blrzApplication2',
            name: 'blrzApplication2',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/baoLiRongZi/blrzApplication2')
          },
          {
            path: '/blrzApplication3',
            name: 'blrzApplication3',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/baoLiRongZi/blrzApplication3')
          },

          {
            path: '/blrzApplication4',
            name: 'blrzApplication4',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/baoLiRongZi/blrzApplication4')
          }
        ]
      },
      // 供货贷产品申请
      {
        path: '/sjghdApplication',
        name: 'sjghdApplication',
        redirect: '/sjghdApplication1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/ShangLian/shuJuGongHuoDai/sjghdApplication'),
        children: [
          {
            path: '/sjghdApplication1',
            name: 'sjghdApplication1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/shuJuGongHuoDai/sjghdApplication1')
          },
          {
            path: '/sjghdApplication2',
            name: 'sjghdApplication2',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/shuJuGongHuoDai/sjghdApplication2')
          },
          {
            path: '/sjghdApplication3',
            name: 'sjghdApplication3',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/shuJuGongHuoDai/sjghdApplication3')
          },
          {
            path: '/sjghdApplication4',
            name: 'sjghdApplication4',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/shuJuGongHuoDai/sjghdApplication4')
          },
          //用信申请
          {
            path: '/yongXinApply',
            name: 'yongXinApply',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/yongXinApply')
          }
        ]
      },
      // 产业E贷产品申请
      {
        path: '/cyedApplication',
        name: 'cyedApplication',
        redirect: '/cyedApplication1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/ShangLian/chanYeEDai/cyedApplication'),
        children: [
          {
            path: '/cyedApplication1',
            name: 'cyedApplication1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/chanYeEDai/cyedApplication1')
          },
          {
            path: '/cyedApplication2',
            name: 'cyedApplication2',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/chanYeEDai/cyedApplication2')
          },
          {
            path: '/ghdApplication',
            name: '供货贷/采购贷产品申请',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/chanYeEDai/ghdApplication')
          },
          {
            path: '/gcqxdApplication',
            name: '工程企信贷产品申请',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/index')
          },
          {
            path: '/gcqxdApplication2',
            name: '工程企信贷产品申请第二步',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/gcqxdApplication2')
          },
          {
            path: '/gcqxdApplication3',
            name: '工程企信贷产品申请第三步',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/gcqxdApplication3')
          },
          {
            path: '/fkcnhApply',
            name: '工程企信贷付款承诺函产品申请',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/fkcnhApply')
          },
          {
            path: '/GCQXDYongXinApply',
            name: '工程企信贷用信申请',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/GCQXDYongXinApply')
          },
          {
            path: '/FkcnYongXinApply',
            name: '付款承诺保函用信申请',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/FkcnYongXinApply')
          },
          {
            path: '/GCQXDYongXinApply1',
            name: '工程企信贷用信申请1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/GongChrngQiXinDai/GCQXDYongXinApply1')
          },
          // 商票保贴产品申请
          {
            path: '/SPBTApply',
            name: '商票保贴产品申请',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/ShangPiaoBaoTie/SPBTApply.vue')
          },
          {
            path: '/cyedApplication3',
            name: 'cyedApplication3',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/ShangLian/chanYeEDai/cyedApplication3')
          }
        ]
      },
      // 普惠驿
      {
        path: '/PuHui',
        name: '普惠驿',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/PuHui/index')
      },
      // 普惠驿 中标贷产品申请流程
      {
        path: '/ZBDApply',
        name: 'ZBDApply',
        redirect: '/ZBDApply1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/PuHui/applyFor/ZhongBiaoDai/ZBDApply'),
        children: [
          {
            path: '/ZBDApply1',
            name: 'ZBDApply1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/PuHui/applyFor/ZhongBiaoDai/ZBDApply1')
          },
          {
            path: '/ZBDApply2',
            name: 'ZBDApply2',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/PuHui/applyFor/ZhongBiaoDai/ZBDApply2')
          }
        ]
      },
      // 普惠驿 普惠数字贷产品申请流程
      {
        path: '/phszdApply',
        name: 'phszdApply',
        redirect: '/phszdApply1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/PuHui/PuHuiShuZiDai/phszdApply'),
        children: [
          {
            path: '/phszdApply1',
            name: 'phszdApply1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/PuHui/PuHuiShuZiDai/phszdApply1')
          }
        ]
      },
      // 普惠驿 产业普惠贷产品申请流程
      {
        path: '/cyphdApply',
        name: 'cyphdApply',
        redirect: '/cyphdApply1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/PuHui/ChanYePuHuiDai/cyphdApply'),
        children: [
          {
            path: '/cyphdApply1',
            name: 'cyphdApply1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/PuHui/ChanYePuHuiDai/cyphdApply1')
          }
        ]
      },
      // 普惠驿 工程履约保函产品申请流程
      {
        path: '/gclybhApply',
        name: 'gclybhApply',
        redirect: '/gclybhApply1',
        meta: {
          title: '数字金融服务平台-商驿科技',
          index: 10,
          auth: true
        },
        component: () => import('@/views/PuHui/GongChengLvYueBaoHan/gclybhApply'),
        children: [
          {
            path: '/gclybhApply1',
            name: 'gclybhApply1',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/PuHui/GongChengLvYueBaoHan/gclybhApply1')
          },
          {
            path: '/gclybhApply2',
            name: 'gclybhApply2',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 11,
              auth: true
            },
            component: () => import('@/views/PuHui/GongChengLvYueBaoHan/gclybhApply2')
          }
        ]
      },
      // 用户中心
      {
        path: '/userCenter',
        name: '用户中心',
        redirect: '/platform',
        meta: {
          title: '数字金融服务平台-商驿科技'
        },
        component: () => import('@/views/userCenter/index'),
        children: [
          {
            path: '/kaiPiao',
            name: '开票',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/kaiPiao')
          },
          {
            path: '/contractMessage',
            name: '合同管理',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/contractMessage')
          },

          {
            path: '/projectShangPiao',
            name: '商票驿项目管理',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangPiao/index')
          },
          // 我的询价详情页
          {
            path: '/projectBusinessDetails',
            name: 'projectBusinessDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangPiao/projectBusinessDetails')
          },
          // 贴现详情页拒绝/审批中
          {
            path: '/projectDiscountDetails',
            name: 'projectDiscountDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangPiao/tiexian/projectDiscountDetails')
          },
          // 贴现详情页审批通过
          {
            path: '/projectApproveEasypasteDetails',
            name: 'projectApproveEasypasteDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () =>
              import('@/views/project/shangPiao/tiexian/projectApproveEasypasteDetails')
          },
          // 质押详情页拒绝/审批中
          {
            path: '/projectPledgeApproveDetalis',
            name: 'projectPledgeApproveDetalis',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangPiao/zhiya/projectPledgeApproveDetalis')
          },
          // 质押详情页审批通过
          {
            path: '/projectApprovePledgeDetails',
            name: 'projectApprovePledgeDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangPiao/zhiya/projectApprovePledgeDetails')
          },
          // 跨境驿项目管理
          {
            path: '/projectKuaJing',
            name: 'projectKuaJing',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/index')
          },
          // 关税保函预授信产品详情
          {
            path: '/projectBorderDetailsGsbh',
            name: '跨境驿项目管理',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/gsbh/projectBorderDetailsGsbh')
          },
          // 关税保函正式申请产品详情
          {
            path: '/regularProjectGsbh',
            name: 'regularProjectGsbh',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/gsbh/regularProjectGsbh')
          },
          // 关税保证保险
          {
            path: '/projectGuaranteeDetails',
            name: 'projectGuaranteeDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/gsbzbx/projectGuaranteeDetails')
          },
          // 关税E保
          {
            path: '/projectBorderDetails',
            name: 'projectBorderDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/gseb/projectBorderDetails')
          },
          // 关税履约保证保险
          {
            path: '/projectGslyBzbx',
            name: 'projectGslyBzbx',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/gslybzbx/projectGslyBzbx')
          },
          // 税信保预授信
          {
            path: '/projectBorderDetailsSxb',
            name: 'projectBorderDetailsSxb',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/sxb/projectBorderDetailsSxb')
          },
          // 税信保正式申请
          {
            path: '/regularProjectSxb',
            name: 'regularProjectSxb',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/kuaJing/sxb/regularProjectSxb')
          },
          // 商链驿项目管理
          {
            path: '/projectShangLian',
            name: 'projectShangLian',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/index')
          },

          // 商链驿 保理融资 授信 项目详情
          {
            path: '/projectBaoLiRongZiDetails',
            name: 'projectBaoLiRongZiDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () =>
              import('@/views/project/shangLian/creditExtension/projectBaoLiRongZiDetails')
          },
          // 商链驿 供货贷 授信 项目详情
          {
            path: '/projectSjghdDetails',
            name: 'projectSjghdDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/creditExtension/projectSjghdDetails')
          },
          // 商链驿 产业E贷 拒绝 授信 项目详情
          {
            path: '/projectChanYeEDaiJJ',
            name: 'projectChanYeEDaiJJ',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/creditExtension/projectChanYeEDaiJJ')
          },
          // 商链驿 产业E贷 审批通过 项目详情
          {
            path: '/projectChanYeEDaiTG',
            name: 'projectChanYeEDaiTG',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/creditExtension/projectChanYeEDaiTG')
          },
          // 商链驿 产业E贷 审批通过 项目详情
          {
            path: '/projectGongHuoDaiTG',
            name: 'projectGongHuoDaiTG',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/gongHuoDai/projectGongHuoDaiTG')
          },
          // 商链驿 产业E贷 审批通过 项目详情
          {
            path: '/projectGongHuoDaiJJ',
            name: 'projectGongHuoDaiJJ',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/gongHuoDai/projectGongHuoDaiJJ')
          },
          // 商链驿 商票保贴
          {
            path: '/projectSPBT',
            name: 'projectSPBT',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () =>
              import('@/views/project/shangLian/ShangPiaoBaoTie/ShouXin/projectSPBT.vue')
          },
          // 商链驿 保理融资 用信 项目详情
          {
            path: '/yongXinBaoLiRongZiDetails',
            name: 'yongXinBaoLiRongZiDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/yongXin/yongXinBaoLiRongZiDetails')
          },
          // 商链驿 供货贷 用信 项目详情
          {
            path: '/yongXinGongHuoDaiDetails',
            name: 'yongXinGongHuoDaiDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/yongXin/yongXinGongHuoDaiDetails')
          },
          {
            path: '/GongChengQiXinDaiYongXinDetails',
            name: 'GongChengQiXinDaiYongXinDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () =>
              import('@/views/project/shangLian/yongXin/GongChengQiXinDaiYongXinDetails')
          },
          {
            path: '/FuKuanChengNuoYongXinDetails',
            name: 'FuKuanChengNuoYongXinDetails',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () =>
              import('@/views/project/shangLian/yongXin/FuKuanChengNuoYongXinDetails')
          },
          // 商票保贴用信
          {
            path: '/projectYXSPBT',
            name: 'projectYXSPBT',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () =>
              import('@/views/project/shangLian/ShangPiaoBaoTie/YongXin/projectYXSPBT.vue')
          },
          // 商链驿 产业E贷 用信 项目详情
          {
            path: '/yongXinChanYeEDai',
            name: 'yongXinChanYeEDai',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/shangLian/yongXin/yongXinChanYeEDai')
          },
          // 普惠驿项目管理
          {
            path: '/projectPuHui',
            name: 'projectPuHui',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/PuHui/index')
          },
          // 普惠驿中标贷授信详情
          {
            path: '/projectZBD',
            name: 'projectZBD',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/PuHui/ZhongBiaoDai/projectZBD')
          },
          // 普惠驿中标贷用信详情
          {
            path: '/yongXinZBD',
            name: 'yongXinZBD',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/PuHui/ZhongBiaoDai/yongXin/yongXinZBD')
          },
          // 普惠驿 普惠数字贷 授信
          {
            path: '/projectPHSZD',
            name: 'projectPHSZD',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/PuHui/PuHuiShuZiDai/projectPHSZD')
          },
          // 普惠驿 产业普惠贷 授信
          {
            path: '/projectCYPHD',
            name: 'projectCYPHD',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/PuHui/ChanYePuHuiDai/projectCYPHD')
          },

          // 普惠驿 工程履约保函 授信
          {
            path: '/projectGCLYBH',
            name: 'projectGCLYBH',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/project/PuHui/GongChengLvYueBaoHan/projectGCLYBH')
          },

          // 认证信息
          // 我的平台
          {
            path: '/platform',
            name: 'platform',
            meta: {
              title: '数字金融服务平台-商驿科技',
              index: 10,
              auth: true
            },
            component: () => import('@/views/attestation/platform.vue')
          },
          {
            path: '/authentication',
            name: '认证信息',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/authentication')
          },
          {
            path: '/authentication1',
            name: 'authentication1',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/authentication1')
          },
          {
            path: '/authentication2',
            name: 'authentication2',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/authentication2')
          },
          {
            path: '/authentication3',
            name: 'authentication3',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/authentication3')
          },
          {
            path: '/authentication4',
            name: 'authentication4',
            meta: {
              title: '数字金融服务平台-商驿科技'
            },
            component: () => import('@/views/attestation/authentication4')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: '登录页',
    meta: {
      title: '用户登录-数字金融服务平台-商驿科技'
    },
    component: () => import('../views/login')
  },
  {
    path: '/loginreg',
    name: '账号注册',
    meta: {
      title: '用户登录-数字金融服务平台-商驿科技'
    },
    component: () => import('../views/login/loginreg')
  },
  {
    path: '/updataPwd',
    name: '忘记密码',
    meta: {
      title: '用户登录-数字金融服务平台-商驿科技'
    },
    component: () => import('../views/login/updataPwd')
  },
  {
    path: '/setPassword',
    name: '设置密码',
    meta: {
      title: '用户登录-数字金融服务平台-商驿科技'
    },
    component: () => import('../views/login/setPassword')
  },
  {
    path: '/success',
    name: '成功',
    meta: {
      title: '认证结果',
      index: 14,
      auth: true
    },
    component: () => import('@/views/result/success')
  },
  {
    path: '/error',
    name: '失败',
    meta: {
      title: '认证结果',
      index: 14,
      auth: true
    },
    component: () => import('@/views/result/error')
  },
  {
    path: '/initial',
    name: '等等',
    meta: {
      title: '人脸验证',
      index: 14,
      auth: true
    },
    component: () => import('@/views/result/initial')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  // 跳转之后滚动条回到顶部
  window.scrollTo(0, 0)
})

export default router
