import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import axios from 'axios'
import { base } from '@/utils'
export default {
  install(Vue, options) {
    Vue.prototype.exportToPdf = function (domId, fileName, objecttype, id) {
      html2Canvas(document.getElementById(domId), {
        allowTaint: true,
        dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
        scale: 4 //按比例增加分辨率
      }).then(function (canvas) {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        const pageHeight = (contentWidth / 592.28) * 841.89
        let leftHeight = contentHeight
        let position = 0
        const imgWidth = 595.28
        const imgHeight = (592.28 / contentWidth) * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        const PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(fileName + '.pdf')
		
        // 上传PDF到服务器的代码
        const formData = new FormData()
        formData.append('file', PDF.output('blob'), 'file.pdf')
        try {
          axios.post(
            base + `/api/filess/YXFile?objecttype=${objecttype}&itemname=${fileName}-样例&id=${id}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'x-auth': sessionStorage.getItem('token')
              }
            }
          )
        } catch (error) {}
      })
    }
  }
}
