<template>
  <div class="box">
    <el-container class="home-container">
      <el-header>
        <div class="content">
          <div class="logo" @click="home">
            <img src="@/assets/image/logo16.png" alt="" />
            <span>商驿企服数字科技平台</span>
          </div>

          <!-- 导航栏开始 -->
          <div class="navigation">
            <ul>
              <li :class="{ active: row == 1 }" @click="goHome">首页</li>
              <li :class="{ active: row == 2 }" @click="goFinancialServe">商票驿</li>
              <li :class="{ active: row == 3 }" @click="goProject">跨境驿</li>
              <!-- @click="gouserCenter" -->
              <li :class="{ active: row == 4 }" @click="gouserCenter">商链驿</li>
              <li :class="{ active: row == 5 }" @click="gouserCenterPh">普惠驿</li>
            </ul>
          </div>
          <div class="register">
            <ul v-if="!loginState">
              <li @click="goLogin">登录</li>
              <li>|</li>
              <li @click="goLoginreg" style="color: #3f6df1">注册</li>
            </ul>
            <div class="userinfo_box" v-else>
              <el-dropdown>
                <img src="@/assets/image/首页0822/组92.png" alt="" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="entname != 'null'">
                    <img style="width: 0.0781rem" src="@/assets/image/首页0822/组94.png" alt="" />
                    消息中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="updateoPwdVisible = true">
                    <img style="width: 0.0781rem" src="@/assets/image/首页0822/组95.png" alt="" />
                    修改密码</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="centerDialogVisible = true">
                    <img style="width: 0.0781rem" src="@/assets/image/首页0822/组96.png" alt="" />
                    退出系统</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div class="userinfo">
                <h5 v-if="entname != 'null'">{{ userPhone }}</h5>
                <el-dropdown v-if="entname == 'null'">
                  <span class="el-dropdown-link">
                    <div class="userinfo-info" style="display: flex">
                      <h4 class="entname">{{ userPhone }}</h4>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div
                      class="approve"
                      v-if="
                        coopid == 'null' ||
                        rzstatus != 'succ' ||
                        rzstatus != '02' ||
                        rzstatus != '01'
                      "
                    >
                      <p>您未完成认证</p>
                      <div class="approve-rz">
                        <i
                          class="el-icon-edit-outline"
                          style="font-size: 0.0833rem; margin-right: 0.026prem"
                        ></i>
                        <span @click="updateCoopeorgInvoice()">立即认证</span>
                      </div>
                    </div>
                    <div class="approve" v-else>
                      <template v-if="ents">
                        <div class="firm" v-if="ent">
                          <p @click="updataToken(ent.customerid, ent.entname)">
                            {{ ent.entname }}
                          </p>
                          <span>当前默认</span>
                        </div>
                        <div class="firm" v-for="item in ents" :key="item.entname">
                          <p
                            style="color: #808080"
                            @click="updataToken(item.customerid, item.entname)"
                          >
                            {{ item.entname }}
                          </p>
                          <span
                            @click="upDefault(item.customerid)"
                            style="color: #48a0b8; border-color: #48a0b8"
                            >默认设置</span
                          >
                        </div>
                        <div class="approve-rz">
                          <i
                            class="el-icon-edit-outline"
                            style="font-size: 0.0833rem; margin-right: 0.026rem"
                          ></i>
                          <span @click="updateCoopeorgInvoice()">新增认证</span>
                        </div>
                      </template>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-else>
                  <span class="el-dropdown-link">
                    <div class="userinfo-info" style="display: flex">
                      <!-- 企业名称 -->
                      <h5 v-if="entname != 'null'">{{ entname }}</h5>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div
                      class="approve"
                      v-if="rzstatus !== 'succ' && rzstatus !== '02' && rzstatus != '01'"
                    >
                      <p>您未完成认证</p>
                      <div class="approve-rz">
                        <i
                          class="el-icon-edit-outline"
                          style="font-size: 0.0833rem; margin-right: 8px"
                        ></i>
                        <span @click="updateCoopeorgInvoice()">立即认证</span>
                      </div>
                    </div>
                    <div class="approve" v-else>
                      <template v-if="ents">
                        <div class="firm" v-if="ent">
                          <p>
                            {{ ent.entname }}
                          </p>
                          <span>当前默认</span>
                        </div>
                        <div
                          class="firm"
                          v-for="item in ents"
                          :key="item.entname"
                          @click="cutEnterprise(item.customerid, item.entname)"
                        >
                          <!-- @click="updataToken(item.customerid, item.entname)" -->
                          <p style="color: #808080">
                            {{ item.entname }}
                          </p>
                          <!-- @click="upDefault(item.customerid, item.entname)" -->
                          <span style="color: #3f6ef2; border-color: #3f6ef2">默认设置</span>
                        </div>
                        <div class="approve-rz">
                          <i
                            class="el-icon-edit-outline"
                            style="font-size: 0.0833rem; margin-right: 0.026rem"
                          ></i>
                          <span @click="updateCoopeorgInvoice()">新增认证</span>
                        </div>
                      </template>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <!-- 下拉 -->
              <div @click="goUserCenters" class="userinfo_btn">用户中心</div>
            </div>
          </div>
        </div>
        <!-- 导航栏结束 -->
      </el-header>
      <el-container>
        <el-main>
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
    <!--  -->
    <el-drawer
      class="drawer"
      title=""
      :modal="false"
      :visible.sync="drawer"
      direction="ltr"
      :before-close="handleClose"
      size="70%"
    >
      <ul>
        <li :class="{ active1: row == 1 }" @click="goHome">
          <span>首页</span>
        </li>
        <li @click="goFinancialServe" :class="{ active1: row == 2 }">
          <span>商票驿</span>
        </li>
        <li @click="goProject" :class="{ active1: row == 3 }">
          <span>跨境驿</span>
        </li>
        <li @click="gouserCenter" :class="{ active1: row == 4 }">
          <span>商链驿</span>
        </li>
        <li :class="{ active1: row == 8 }">
          <span>普惠驿</span>
        </li>
        <li @click="row = 5" :class="{ active1: row == 5 }">
          <span>登录</span>
        </li>
        <li @click="row = 6" :class="{ active1: row == 6 }">
          <span>注册</span>
        </li>
      </ul>
    </el-drawer>
    <!-- 弹窗 -->
    <!-- 退出登录弹框 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="32%">
      <!-- 内容区域 -->
      <div class="dialog-center">
        <p>
          <img src="@/assets/image/组69.png" alt="" />
          您确定要退出系统吗？
        </p>
      </div>
      <!-- 按钮区域 -->
      <div slot="footer" class="dialog-footer">
        <button class="dialog-footer-bnt btn1" @click="centerDialogVisible = false">取 消</button>
        <button class="dialog-footer-bnt btn2" @click="quit()">确 定</button>
      </div>
    </el-dialog>
    <!-- 修改密码弹框 -->
    <el-dialog
      class="password"
      title="修改密码"
      @close="close"
      :closeOnClickModal="false"
      :visible.sync="updateoPwdVisible"
      width=""
    >
      <!-- 内容区域 -->
      <div class="dialog-center">
        <ul>
          <li>
            <el-form ref="form" :model="form" :rules="formotherRules" label-width="100px">
              <el-form-item label="原密码" prop="oldpassword">
                <el-input
                  v-model="form.oldpassword"
                  placeholder="请输入原密码"
                  type="password"
                  @blur="oldpassworda"
                ></el-input>
                <p class="error" v-show="oldPwd">原密码错误</p>
              </el-form-item>
            </el-form>
          </li>
          <li>
            <el-form ref="form" :model="form" :rules="formotherRules" label-width="100px">
              <el-form-item label="新密码" prop="confirmPwd">
                <el-input
                  v-model="form.confirmPwd"
                  type="password"
                  placeholder="请输入新密码，8-16位字母+数字组合"
                  @blur="b"
                ></el-input>
                <p class="error" v-show="confirmPwd">字母+数字组合，8-16位</p>
              </el-form-item>
            </el-form>
          </li>
          <li>
            <el-form ref="form" :model="form" :rules="formotherRules" label-width="100px">
              <el-form-item label="确认新密码" prop="newpassword">
                <el-input
                  type="password"
                  v-model="form.newpassword"
                  placeholder="请确认新密码"
                  @blur="newpassword"
                ></el-input>
                <p class="error" v-show="newPwd">{{ newHint }}</p>
              </el-form-item>
            </el-form>
          </li>
        </ul>
      </div>
      <!-- 按钮区域 -->
      <!--  -->
      <!-- -->
      <div slot="footer" class="dialog-footer">
        <button class="dialog-footer-bnt btn1" @click="updateoPwdVisible = false">取 消</button>
        <button class="dialog-footer-bnt btn2" @click="updatePWD()">确 定</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { mapState } from 'vuex'
import { userEnt, userupdateCoopid, updataPwd, userEnts } from '@/utils'
export default {
  computed: {
    ...mapState(['id'])
  },
  components: { Footer },
  data() {
    return {
      coopid: '',
      rzstatus: '',
      date: '',
      financialRow: '1', // 金融服务状态变量
      projectRow: '1', // 项目管理状态变量
      platformRow: '1', //我的平台状态变量
      ent: null, //当前默认认证企业
      ents: null, //设置默认认证企业
      a: '',
      loginState: null,
      userPhone: '',
      entname: '',
      row: 1,
      drawer: false,
      centerDialogVisible: false, //退出登录弹框状态变量
      updateoPwdVisible: false, //修改密码弹框状态变量
      oldPwd: null,
      confirmPwd: null,
      newPwd: null,
      newHint: '两次密码不一致，请重新输入',
      form: {
        oldpassword: '', //旧密码
        confirmPwd: '', //新密码
        newpassword: '' //确认新密码
      },
      // 表单验证
      formotherRules: {
        oldpassword: [{ required: true, message: ' ', trigger: 'blur' }],
        confirmPwd: [{ required: true, message: ' ', trigger: 'blur' }],
        newpassword: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.coopid = sessionStorage.getItem('coopid')
    this.rzstatus = sessionStorage.getItem('rzstatus')
    this.a = sessionStorage.getItem('id')
    this.userPhone = sessionStorage.getItem('userPhone')
    setTimeout(() => {
      this.entname = sessionStorage.getItem('entname')
    }, 500)
    this.loginState = sessionStorage.getItem('loginState')
    this.status()
    this.updataActive()
    this.get()
    this.getWidth()
  },
  methods: {
    // 获取屏幕宽度
    getWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth < 950) {
        const a = document.createElement('a') // 动态创建a标签，防止下载大文件时，用户没看到下载提示连续点击
        a.href = 'http://mv3.thunlink.com/'
        // a.href = "http://192.168.3.46:8081/";
        a.click()
        window.URL.revokeObjectURL(url)
        return
      }
    },

    // 跳转至平台认证页
    updateCoopeorgInvoice() {
      localStorage.removeItem('succ')
      let rzstatus = sessionStorage.getItem('rzstatus')
      if (rzstatus == 'succ' || rzstatus == '01' || rzstatus == '02') {
        this.$router.push('/authentication1?state=true')
        this.setTime()
      } else {
        this.$router.push('/authentication')
        this.setTime()
      }
    },
    setTime() {
      setTimeout(() => {
        this.$router.go(0)
      }, 500)
    },
    // 点击企业名称更新token
    async updataToken(customerid, entname) {
      let data = await userEnts(customerid)
      if (data.msg == 'ok') {
        sessionStorage.setItem('token', data.data.token)
        this.$store.commit('updateToken', data.data.token)
        sessionStorage.setItem('customerid', customerid)
        this.$store.commit('updataCustomerid', customerid)
        sessionStorage.setItem('entname', entname)
        sessionStorage.setItem('coopid', customerid)
        // this.$store.commit("updateEntname", entname);
        sessionStorage.setItem('rzstatus', data.data.ent.rzstatus)
      }
    },
    // 认证状态列表
    async status() {
      if (
        this.coopid == 'null' ||
        (this.rzstatus != 'succ' && this.rzstatus != '02' && this.rzstatus != '01')
      ) {
        return
      } else {
        let { data } = await userEnt(this.a)
        let ents = []
        if (data.ent) {
          data.ents.forEach(item => {
            if (item.entname !== data.ent.entname) {
              ents.push(item)
            }
          })
          // sessionStorage.setItem("entname", data.ent.entname);
          // this.$store.commit("updateUserName", data.ent.entname);
          this.ent = data.ent
          this.ents = ents
        } else {
          this.ents = data.ents
        }
      }
    },

    // 切换企业
    async cutEnterprise(customerid, entname) {
      this.updataToken(customerid, entname)
      await userupdateCoopid(customerid)
      this.$Message.success('切换成功')
      if (this.$route.path == '/home')
        return setTimeout(() => {
          this.$router.go(0)
        }, 1000)
      this.$router.push('/')
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 跳转登录页
    goLogin() {
      this.$router.push('/login')
    },
    // 跳转注册页
    goLoginreg() {
      this.$router.push('/loginreg')
    },
    // 跳转用户中心
    goUserCenters() {
      if (
        this.$route.path == '/platform' ||
        this.$route.path == '/userCenter' ||
        this.$route.path == '/projectShangPiao' ||
        this.$route.path == '/projectBusinessDetails' ||
        this.$route.path == '/projectPledgeApproveDetalis' ||
        this.$route.path == '/projectApprovePledgeDetails' ||
        this.$route.path == '/projectApproveEasypasteDetails' ||
        this.$route.path == '/projectDiscountDetails' ||
        this.$route.path == '/projectKuaJing' ||
        this.$route.path == '/projectBorderDetailsGsbh' ||
        this.$route.path == '/regularProjectGsbh' ||
        this.$route.path == '/projectGuaranteeDetails' ||
        this.$route.path == '/projectBorderDetails' ||
        this.$route.path == '/projectGslyBzbx' ||
        this.$route.path == '/projectBorderDetailsSxb' ||
        this.$route.path == '/regularProjectSxb' ||
        this.$route.path == '/authentication' ||
        this.$route.path == '/projectShangLian'
      )
        return
      this.row = 99
      localStorage.clear()
      this.$router.push('/userCenter')
    },
    handleClose(done) {
      done()
    },
    // 切换
    cutDrawer() {
      if (this.drawer) {
        this.drawer = false
      } else {
        this.drawer = true
      }
    },
    // 关闭弹窗
    close() {
      this.form.oldpassword = ''
      this.form.confirmPwd = ''
      this.form.newpassword = ''
    },
    // 点击退出弹框按钮确认键
    quit() {
      this.$router.push('/login')
      sessionStorage.clear()
      setTimeout(() => {
        this.$router.go(0)
      }, 500)
    },
    // 修改密码
    // 校验原密码
    oldpassworda() {
      this.oldPwd = false
    },
    // 校验新密码
    b() {
      this.confirmPwd = false
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
      if (!reg.test(this.form.confirmPwd)) {
        this.form.confirmPwd = ''
        this.confirmPwd = true
      }
    },
    // 确认新密码
    newpassword() {
      this.newPwd = false
      if (this.form.confirmPwd != this.form.newpassword) {
        this.newHint = '两次密码不一致，请重新输入'
        this.form.newpassword = ''
        this.newPwd = true
      }
    },
    // 修改密码点击确认按钮
    async updatePWD() {
      if (
        (this.form.oldpassword.trim() != '') &
        (this.form.confirmPwd.trim() != '') &
        (this.form.newpassword.trim() != '') &
        (this.form.confirmPwd == this.form.newpassword)
      ) {
        let data = await updataPwd(this.form)
        if (data.code == 330) {
          this.oldPwd = 1
        } else if (data.code == 99) {
          this.confirmPwd = 1
        } else if (data.code == 310) {
          this.newPwd = 1
          this.newHint = '新密码不能和旧密码一致'
        } else if (data.code == 100) {
          this.$Message.success('密码修改成功')
          this.updateoPwdVisible = false
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
          setTimeout(() => {
            this.$router.go(0)
          }, 1500)
        }
      } else {
        this.$Message.error('两次密码不一致，请重新输入')
      }
    },
    home() {
      if (this.$route.path == '/home') return
      this.$router.push('/home')
      setTimeout(() => {
        this.$router.go(0)
      }, 500)
    },
    // 首页
    goHome() {
      this.row = 1
      this.drawer = false
      if (this.$route.path == '/home') return
      this.$router.push('/home')
    },
    // 商票驿
    goFinancialServe() {
      this.row = 2
      this.drawer = false
      if (this.$route.path == '/ShangPiao') return
      this.$router.push('/ShangPiao')
    },
    // 跨境
    goProject() {
      this.row = 3
      this.drawer = false
      if (this.$route.path == '/KuaJing') return
      this.$router.push('/KuaJing')
    },
    // 商链驿
    gouserCenter() {
      this.row = 4
      this.drawer = false
      if (this.$route.path == '/ShangLian') return
      this.$router.push('/ShangLian')
    },
    // 普惠驿
    gouserCenterPh() {
      this.row = 5
      this.drawer = false
      if (this.$route.path == '/PuHui') return
      this.$router.push('/PuHui')
    },

    // 保证页面刷新之后，选中项在当前页面侧边栏上
    updataActive() {
      if (this.$route.path == '/home') {
        this.row = 1
      } else if (
        this.$route.path == '/ShangPiao' ||
        this.$route.path == '/enquiry' ||
        this.$route.path == '/billAdministration' ||
        this.$route.path == '/sptxApplication' ||
        this.$route.path == '/sptxApplication1' ||
        this.$route.path == '/spzyApplication' ||
        this.$route.path == '/spzyApplication1'
      ) {
        this.row = 2
      } else if (
        this.$route.path == '/KuaJing' ||
        this.$route.path == '/gsbhApplocation' ||
        this.$route.path == '/gsbhApplocation1' ||
        this.$route.path == '/gsbhzsApplocation' ||
        this.$route.path == '/gsbzbxApplocation' ||
        this.$route.path == '/gsbzbxApplocation1' ||
        this.$route.path == '/gsebApplocation' ||
        this.$route.path == '/gsebApplocation1' ||
        this.$route.path == '/gslybzbxApplocation' ||
        this.$route.path == '/gslybzbxApplocation1' ||
        this.$route.path == '/sxbApplocation' ||
        this.$route.path == '/sxbApplocation1' ||
        this.$route.path == '/sxbCreditExtension' ||
        this.$route.path == '/sxbCreditExtension2'
      ) {
        this.row = 3
      } else if (
        this.$route.path == '/ShangLian' ||
        this.$route.path == '/transitionPage' ||
        this.$route.path == '/sjghdApplication' ||
        this.$route.path == '/sjghdApplication1' ||
        this.$route.path == '/sjghdApplication2' ||
        this.$route.path == '/sjghdApplication3' ||
        this.$route.path == '/sjghdApplication4' ||
        this.$route.path == '/blrzApplication' ||
        this.$route.path == '/blrzApplication1' ||
        this.$route.path == '/blrzApplication2' ||
        this.$route.path == '/blrzApplication3' ||
        this.$route.path == '/blrzApplication4' ||
        this.$route.path == '/cyedApplication' ||
        this.$route.path == '/cyedApplication1' ||
        this.$route.path == '/cyedApplication2' ||
        this.$route.path == '/cyedApplication3' ||
        this.$route.path == '/gcqxdApplication' ||
        this.$route.path == '/gcqxdApplication2' ||
        this.$route.path == '/gcqxdApplication3' ||
        this.$route.path == '/ghdApplication' ||
        this.$route.path == '/GCQXDYongXinApply' ||
        this.$route.path == '/GCQXDYongXinApply1' ||
        this.$route.path == '/fkcnhApply' ||
        this.$route.path == '/FkcnYongXinApply' ||
        this.$route.path == '/SPBTApply'
      ) {
        this.row = 4
      } else if (
        this.$route.path == '/PuHui' ||
        this.$route.path == '/ZBDApply' ||
        this.$route.path == '/ZBDApply1' ||
        this.$route.path == '/gclybhApply' ||
        this.$route.path == '/gclybhApply1' ||
        this.$route.path == '/gclybhApply2' ||
        this.$route.path == '/phszdApply' ||
        this.$route.path == '/phszdApply1' ||
        this.$route.path == '/cyphdApply' ||
        this.$route.path == '/cyphdApply1'
      ) {
        this.row = 5
      } else {
        this.row = 999
      }
    },
    // 获取浏览器信息
    get() {
      var userAgent = navigator.userAgent // 获取浏览器的user agent信息
      var version = null
      if (/MSIE (\d+\.\d+);/.test(userAgent)) {
        // 匹配IE浏览器
        version = parseFloat(RegExp.$1)
      } else if (/Firefox\/([\d.]+)/.test(userAgent)) {
        // 匹配Firefox浏览器
        version = parseFloat(RegExp.$1)
      } else if (/Chrome\/([\d.]+)/.test(userAgent)) {
        // 匹配Chrome浏览器
        version = parseFloat(RegExp.$1)
      } else if (/Version\/([\d.]+).*Safari/.test(userAgent)) {
        // 匹配Safari浏览器
        version = parseFloat(RegExp.$1)
      } else if (/Opera\/([\d.]+)/.test(userAgent)) {
        // 匹配Opera浏览器
        version = parseFloat(RegExp.$1)
      }
      var engine = null

      if (/Trident\/([\d.]+)/.test(userAgent)) {
        // 匹配Trident内核（IE浏览器）
        engine = 'Trident'
      } else if (/Gecko\/([\d.]+)/.test(userAgent)) {
        // 匹配Gecko内核（Firefox浏览器）
        engine = 'Gecko'
      } else if (/AppleWebKit\/([\d.]+)/.test(userAgent)) {
        // 匹配Webkit内核（Chrome、Safari浏览器）
        engine = 'Webkit'
      } else if (/Presto\/([\d.]+)/.test(userAgent)) {
        // 匹配Presto内核（Opera浏览器）
        engine = 'Presto'
      }
      // 获取手机型号
      var deviceInfo = {
        isiOS: /iPhone|iPad|iPod/i.test(navigator.userAgent), // 是否为iOS设备
        isAndroid: /Android/i.test(navigator.userAgent) // 是否为Android设备
      }
      if (deviceInfo.isAndroid && engine == 'Webkit' && version < 100) {
        this.$confirm(
          '您的浏览器版本过低，为了您更好的体验，推荐使用目前市场主流浏览器(谷歌，火狐，夸克，UC，Edge)或使用PC端访问',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }
        )
          .then(() => {
            const imgUrlStr = 'http://www.firefox.com.cn/download/thanks/'
            const a = document.createElement('a') // 动态创建a标签，防止下载大
            a.href = imgUrlStr
            a.target = '_black'
            a.click()
            window.URL.revokeObjectURL(imgUrlStr)
          })
          .catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .home-container {
    height: 100%;
  }
  // 头部区域
  .el-header {
    z-index: 99999;
    width: 100vw;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    height: 80px !important;
    @media only screen and (min-width: 1580px) and (max-width: 1950px) {
      height: 80px !important;
    }
    padding: 0;
    box-shadow: 3px 0px 9px 0px rgba(178, 178, 178, 0.46);

    .content {
      width: 8.4375rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      .logo {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 50px;
        span {
          font-size: 0.1042rem;
          font-weight: bold;
          color: #000000;
          margin-left: 0.0781rem;
        }
        img {
          width: 0.3021rem;
        }
      }
      // 导航栏
      .navigation {
        width: 2.6042rem;
        height: 0.3125rem;
        @media only screen and (min-width: 0) and (max-width: 949px) {
          display: none;
        }
        margin-left: 1.6719rem;
        ul {
          height: 0.3125rem;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          li {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.3906rem;
            height: 0.276rem;
            font-size: 0.1rem;

            font-weight: 400;
            color: #000;
            cursor: pointer;
            &.active {
              color: #3f6df1;
              border-bottom: 0.0208rem solid #3f6df1;
            }
          }
        }
      }
      .register {
        .userinfo_box {
          display: flex;
          align-items: center;
          img {
            width: 0.1927rem;
            margin-right: 0.0521rem;
          }
          .userinfo {
            display: flex;
            flex-direction: column;
            font-size: 0.0833rem;
            color: #9f9b97;
          }
          .userinfo_btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.526rem;
            height: 0.1406rem;
            background: #fff;
            border-radius: 0.0104rem;
            opacity: 1;
            border: 0.0052rem solid #b7b5b4;
            font-size: 0.0833rem;
            color: #333333;
            margin-left: 0.1823rem;
          }
        }

        ul {
          cursor: pointer;
          width: 0.5625rem;
          display: flex;
          justify-content: space-between;
          font-size: 0.0938rem;
          color: #8f9198;
        }
      }
    }

    // .show {
    //   height: 100%;
    //   position: absolute;
    //   right: 30px;
    //   display: flex;
    //   align-items: center;
    //   @media only screen and (min-width: 950px) and (max-width: 2580px) {
    //     display: none;
    //   }
    //   img {
    //     width: 32px;
    //   }
    // }
  }
  // 底部区域
  .el-footer {
    padding: 0px;
  }
  .el-main {
    margin-top: 80px;
    padding: 0;
  }
}
.drawer {
  ul {
    box-sizing: border-box;
    padding: 5px 15px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 50px;
      text-transform: uppercase;
      color: rgba(2552, 255, 255, 0.6);
      max-width: calc(100% - 36px);
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #919eb2;
      &.active1 {
        color: #fff;
      }
      &.active2 {
        color: #fff;
      }
      span {
        width: 100%;
      }
    }
  }
}
// 弹出框
.dialog-center {
  text-align: center;
  overflow: hidden;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    // margin-left: 53px;
    font-size: 16px;
    font-family: 'Microsoft YaHei';
    font-weight: 600;
    color: #4d4d4d;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  margin: 0 auto;
  z-index: 9999;
  .dialog-footer-bnt {
    width: 121px;
    height: 31px;
    color: #3f6ef2;
    border: 1px solid #3f6ef2;
    border-radius: 4px;
    background-color: #fff;
    z-index: 9999;
  }
  .btn2 {
    color: #fff;
    background-color: #3f6ef2;
    z-index: 9999;
  }
  .abc {
    width: 368px;
    .el-select {
      width: 100%;
    }
  }
}
.el-form-item {
  margin-bottom: 16px;
  height: 45px;
  .error {
    height: 15px;
    line-height: 0.0781rem;
    font-size: 0.0677rem;
    color: #f56c6c;
    position: absolute;
    left: 0.1042rem;
    @media only screen and (min-width: 1580px) and (max-width: 1950px) {
      left: 0.1042rem;
    }
  }
}

// 弹窗大小
::v-deep .password .el-dialog {
  overflow: auto;
  @media only screen and (min-width: 1px) and (max-width: 899px) {
    width: 32%;
  }
  // 小屏
  @media only screen and (min-width: 900px) and (max-width: 1535px) {
    width: 32%;
  }
  // 中屏
  @media only screen and (min-width: 1536px) and (max-width: 1919px) {
    width: 29%;
  }
  // 大屏
  @media only screen and (min-width: 1920px) and (max-width: 2580px) {
    width: 27%;
  }
}
::v-deep .el-drawer {
  background-color: #061552;
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
}
::v-deep .el-drawer__body {
  box-sizing: border-box;
  // padding: 5px 25px;
  background-color: #061552;
}
/* 输入框样式  */
::v-deep .el-input__inner {
  box-sizing: border-box;
  width: 1.6667rem;
  // @media only screen and (min-width: 1px) and (max-width: 999px) {
  //   width: 100px;
  // }
  // @media only screen and (min-width: 1000px) and (max-width: 1349px) {
  //   width: 200px;
  // }
  // @media only screen and (min-width: 1350px) and (max-width: 1535px) {
  //   width: 280px;
  // }
  // @media only screen and (min-width: 1535px) and (max-width: 1920px) {
  //   width: 300px;
  // }
  height: 39px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 0px !important;
  padding: 10px 5px;
  color: #606266;
  outline: 0;
}
::v-deep .el-dropdown {
  font-size: 0.0729rem;
  color: #9f9b97;
}
// 认证信息
.approve {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
  // margin-top: 150px;
  width: 360px;

  // height: 80px;
  .firm {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    margin-top: 15px;
    cursor: pointer;
    p {
      font-size: 14px;
      font-family: 'Microsoft YaHei';
      font-weight: 300;
      color: #1a1a1a;
    }
    span {
      height: 20px;
      font-size: 14px;
      font-family: 'Microsoft YaHei';
      font-weight: 300;
      border-bottom: 1px solid #333;
      color: #333333;
      margin-top: -2px;
    }
  }
  p {
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    font-weight: 300;
    color: #808080;
  }
  .approve-rz {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 28px;
    background: #3f6ef2;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    font-weight: 300;
    color: #fff;
    margin-top: 15px;
    cursor: pointer;
  }
}
::v-deep .el-dialog__header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 46px;
  padding: 0.1042rem 0.1042rem 0.0521rem;
  border-bottom: 0.0052rem solid #dddbdb;
}
::v-deep .el-dialog__title {
  font-size: 0.1042rem;
  font-family: SourceHanSansSC-Bold, SourceHanSansSC;
  font-weight: bold;
  color: #3f6df1;
}
</style>
